<template>
  <div class="configurations-div">
    <div class="configurations-content">
      <v-tabs v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="item in tabItemsTemp" :key="item.id">
          {{ item.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="custom-scroll">
        <v-tab-item v-for="item in tabItemsTemp" :key="item.id">
          <div v-if="item.title == 'Usuários'">
            <div class="d-flex">
              <div class="tab-title">Usuários</div>
              <v-spacer></v-spacer>
              <v-text-field outlined placeholder="Pesquisar em usuários" append-icon="mdi-magnify"
                v-model="findUser"></v-text-field>
              <v-btn depressed dark color="#177dff" style="margin-top: 20px; margin-right: 30px"
                @click="newUserModal = true">
                Novo usuário
              </v-btn>
            </div>
            <v-data-table :headers="usersHeaders" :items="UsersFilter(users)" disable-pagination hide-default-footer
              no-data-text="Nenhum resultado encontrado" class="overflow-table custom-scroll" @click:row="selectUser">
              <template v-slot:[`item.photoKey`]="{ item }">
                <UserPhoto size="32" width="32" height="32" :user="item" />
              </template>
            </v-data-table>
          </div>
          <div v-if="item.title == 'Status'">
            <div class="tab-title">Status</div>
            <v-data-table class="overflow-table custom-scroll" :items="statuses" :headers="statusesHeaders"
              hide-default-footer @click:row="rowClicked" v-click-outside-custom="cancelChange"
              no-data-text="Nenhum resultado encontrado" disable-pagination>
              <template v-slot:[`item.alertTime`]="{ item }">
                <div class="d-flex" v-if="item.id == editingRow.id">
                  <v-text-field autofocus :loading="isLoaderActive" :disabled="isLoaderActive" dense hide-details
                    v-model="editingRow.alertTime" class="shrink" style="width: 40px" @keyup.enter="confirmChange"
                    @keyup.esc="cancelChange" type="number"></v-text-field>
                  <div style="display: flex; align-items: flex-end">
                    <span> dias </span>
                  </div>
                </div>
                <template v-else> {{ item.alertTime }} dias </template>
              </template>
            </v-data-table>
            <div style="color: #ebeef7; background-color: #ebeef7">.</div>
          </div>

          <div v-if="item.title === 'Ambientes'">
            <div class="d-flex">
              <div class="tab-title">Ambientes</div>
              <v-spacer></v-spacer>
              <v-btn depressed dark color="#177dff" style="margin-top: 20px; margin-right: 30px"
                @click="newEnvironmentModal = true">
                Novo ambiente
              </v-btn>
            </div>
            <v-data-table class="overflow-table custom-scroll" :items="environments" :headers="environmentsHeaders"
              hide-default-footer no-data-text="Nenhum resultado encontrado" disable-pagination
              @click:row="selectEnvironment">
            </v-data-table>
          </div>
          <div v-if="item.title === 'Chat'">
            <div class="tw-h-full tw-flex tw-gap-10 tw-px-6 tw-py-2">
              <div class="left-menu tw-h-full">
                <v-list class="list-menu">
                  <v-list-item v-for="(option, index) in menuOptions"
                    class="tw-flex tw-items-center tw-gap-2 tw-my-2 tw-rounded-lg" @click="changeOption(index)">
                    <!-- <div v-if="index == 0">
                      <div v-if="selectOption == index">
                        <settings-icon iconColor="#007AFF" iconSize="24" />
                      </div>
                      <div v-else>
                        <settings-icon iconColor="#515151" iconSize="24" />
                      </div>
                    </div> -->
                    <div v-if="index == 0"> <!--<div v-if="index == 1"> -->
                      <div v-if="selectOption == index">
                        <departament-icon iconColor="#007AFF" iconSize="24" />
                      </div>
                      <div v-else>
                        <departament-icon iconColor="#515151" iconSize="24" />
                      </div>
                    </div>
                    <div v-if="index == 1"> <!--<div v-if="index == 2"> -->
                      <div v-if="selectOption == index">
                        <user-settings-icon iconColor="#007AFF" iconSize="24" />
                      </div>
                      <div v-else>
                        <user-settings-icon iconColor="#515151" iconSize="24" />
                      </div>
                    </div>
                    <!-- <div v-if="index == 3">
                      <div v-if="selectOption == index">
                        <shortcut-icon iconColor="#007AFF" iconSize="24" />
                      </div>
                      <div v-else>
                        <shortcut-icon iconColor="#515151" iconSize="24" />
                      </div>
                    </div> -->
                    <div v-if="index == 2"> <!--<div v-if="index == 4"> -->
                      <div v-if="selectOption == index">
                        <tag-icon iconColor="#007AFF" iconSize="24" />
                      </div>
                      <div v-else>
                        <tag-icon iconColor="#515151" iconSize="24" />
                      </div>
                    </div>
                    <div v-if="index == 3"> <!--<div v-if="index == 5"> -->
                      <div v-if="selectOption == index">
                        <check-circle-icon iconColor="#007AFF" iconSize="24" />
                      </div>
                      <div v-else>
                        <check-circle-icon iconColor="#515151" iconSize="24" />
                      </div>
                    </div>
                    <div v-if="index == 4"> <!--<div v-if="index == 6"> -->
                      <div v-if="selectOption == index">
                        <bot-icon iconColor="#007AFF" iconSize="24" />
                      </div>
                      <div v-else>
                        <bot-icon iconColor="#515151" iconSize="24" />
                      </div>
                    </div>
                    <div v-if="selectOption == index" class="primaryColor tw-text-base">
                      {{ option }}
                    </div>
                    <div v-else class="tw-text-sm">
                      {{ option }}
                    </div>
                  </v-list-item>
                  <div class="tw-px-4 tw-mt-4">
                    <div class="tw-mb-2">
                      <v-btn elevation="0" color="#007AFF" height="50" outlined rounded @click="qrGenerator()">
                        <qrcode-icon iconColor="#007AFF" iconSize="40px" />
                        <span class="tw-font-semibold" style="color: #007aff">
                          Gerar novo QR Code
                        </span>
                      </v-btn>
                    </div>
                  </div>
                </v-list>
              </div>
              <div v-for="(option, index) in menuOptions" v-if="index == selectOption" class="tw-w-full tw-py-4 tw-px-8">
                <div class="tw-pb-5" v-if="index == -1"> <!--index == 0-->
                  <div class="tw-flex tw-items-center">
                    <div class="menuTitle">
                      {{ option }}
                    </div>
                  </div>
                  <div class="mt-6 overflow-height custom-scroll">
                    <div>
                      <template>
                        <v-form>
                          <fieldset class="fieldset-input tw-border tw-rounded-md tw-p-5">
                            <legend class="tw-font-semibold">Geral</legend>
                            <div>
                              <div class="tw-flex tw-items-center">
                                <div class="tw-text-sm tw-flex tw-items-center tw-gap-1">
                                  Código Cliente
                                  <v-tooltip bottom max-width="200">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <info-icon iconColor="#515151" iconSize="14px" />
                                      </div>
                                    </template>
                                    <span class="font-tooltip">Código e identificação do cliente</span>
                                  </v-tooltip>
                                </div>
                                <div class="tw-ml-auto">
                                  <v-text-field outlined hide-details="auto" class="tw-w-full"></v-text-field>
                                </div>
                              </div>
                              <div class="tw-flex tw-items-center">
                                <div class="tw-text-sm tw-flex tw-items-center tw-gap-1">
                                  Nome da instância
                                  <v-tooltip bottom max-width="200">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <info-icon iconColor="#515151" iconSize="14px" />
                                      </div>
                                    </template>
                                    <span class="font-tooltip">Nome da sua empresa para identificação no
                                      Whatsapp</span>
                                  </v-tooltip>
                                </div>
                                <div class="tw-ml-auto">
                                  <v-text-field outlined hide-details="auto" class="tw-w-full"></v-text-field>
                                </div>
                              </div>
                              <div class="tw-flex tw-items-center">
                                <div class="tw-text-sm tw-flex tw-items-center tw-gap-1">
                                  Mensagem de boas vindas
                                  <v-tooltip bottom max-width="200">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <info-icon iconColor="#515151" iconSize="14px" />
                                      </div>
                                    </template>
                                    <span class="font-tooltip">Mensagem a ser enviada quando uma sessão
                                      é iniciada pelo cliente</span>
                                  </v-tooltip>
                                </div>
                                <div class="welcome-message tw-ml-auto tw--mr-3">
                                  <template>
                                    <v-container fluid>
                                      <v-textarea no-resize clearable outlined hide-details="auto"
                                        clear-icon="mdi-close-circle"></v-textarea>
                                    </v-container>
                                  </template>
                                </div>
                              </div>
                              <div class="tw-flex tw-items-center">
                                <div class="tw-text-sm tw-flex tw-items-center tw-gap-1">
                                  Mensagem de finalização
                                  <v-tooltip bottom max-width="200">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <info-icon iconColor="#515151" iconSize="14px" />
                                      </div>
                                    </template>
                                    <span class="font-tooltip">Mensagem a ser enviada quando uma sessão
                                      é finalizada sem departamento</span>
                                  </v-tooltip>
                                </div>
                                <div class="welcome-message tw-ml-auto tw--mr-3">
                                  <template>
                                    <v-container fluid>
                                      <v-textarea no-resize clearable outlined hide-details="auto"
                                        clear-icon="mdi-close-circle"></v-textarea>
                                    </v-container>
                                  </template>
                                </div>
                              </div>
                              <div class="">
                                <div class="tw-flex tw-items-center tw--mt-8">
                                  <div class="tw-text-sm tw-flex tw-mt-10 tw-items-center tw-gap-1">
                                    Identificar agente nas conversas (#nome)
                                    <v-tooltip bottom max-width="200">
                                      <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                          <info-icon iconColor="#515151" iconSize="14px" />
                                        </div>
                                      </template>
                                      <span class="font-tooltip">Inserir #nome no início de cada
                                        mensagem enviada pelo agente</span>
                                    </v-tooltip>
                                  </div>
                                  <div class="welcome-message tw-ml-auto tw--mr-3 tw-px-5">
                                    <template>
                                      <v-row>
                                        <v-switch hide-details="auto"></v-switch>
                                      </v-row>
                                    </template>
                                  </div>
                                </div>
                                <div class="tw-flex tw-items-center tw--mt-8">
                                  <div class="tw-text-sm tw-mt-10 tw-flex tw-items-center tw-gap-1">
                                    Ocultar números dos telefones
                                    <v-tooltip bottom max-width="200">
                                      <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                          <info-icon iconColor="#515151" iconSize="14px" />
                                        </div>
                                      </template>
                                      <span class="font-tooltip">Oculta os últimos quatros dígitos dos
                                        leads</span>
                                    </v-tooltip>
                                  </div>
                                  <div class="welcome-message tw-ml-auto tw--mr-3 tw-px-5">
                                    <template>
                                      <v-row>
                                        <v-switch hide-details="auto"></v-switch>
                                      </v-row>
                                    </template>
                                  </div>
                                </div>
                                <div class="tw-flex tw-items-center tw--mt-8">
                                  <div class="tw-text-sm tw-mt-10 tw-flex tw-items-center tw-gap-1">
                                    Avaliação de atendimento
                                    <v-tooltip bottom max-width="200">
                                      <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                          <info-icon iconColor="#515151" iconSize="14px" />
                                        </div>
                                      </template>
                                      <span class="font-tooltip">Avaliação de atendimento ao finalizar
                                        sessão</span>
                                    </v-tooltip>
                                  </div>
                                  <div class="welcome-message tw-ml-auto tw--mr-3 tw-px-5">
                                    <template>
                                      <v-row>
                                        <v-switch hide-details="auto"></v-switch>
                                      </v-row>
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </v-form>
                      </template>
                    </div>
                    <div class="mt-6">
                      <template>
                        <v-form>
                          <fieldset class="fieldset-input finished-inactivity tw-border tw-rounded-md tw-p-5">
                            <legend class="tw-font-semibold">
                              Finalizar sessões por inatividade
                            </legend>
                            <div>
                              <div class="tw-flex tw-items-center tw-p-4 tw-border tw-rounded-xl tw-max-h-20">
                                <div class="tw-text-sm tw-flex tw-items-center tw-gap-1">
                                  Tempo inatividade cliente
                                  <v-tooltip bottom max-width="200">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <info-icon iconColor="#515151" iconSize="14px" />
                                      </div>
                                    </template>
                                    <span class="font-tooltip">Defina, em horas/minutos, o tempo limite
                                      de inatividade (por parte do cliente) para
                                      que o atendimento seja finalizado. Para
                                      desativar, preencha 0.</span>
                                  </v-tooltip>
                                </div>
                                <div class="time-inactivity-client tw-ml-auto">
                                  <v-menu ref="menu" v-model="menuInactivyClient" :close-on-content-click="false"
                                    :nudge-right="40" transition="scale-transition" offset-y max-width="290px"
                                    min-width="290px">
                                    <!-- :return-value.sync="time" -->
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field label="Informe o Horário" v-model="timeInactivityClient"
                                        prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                                        v-on="on"></v-text-field>
                                    </template>
                                    <v-time-picker format="24hr" v-if="menuInactivyClient" v-model="timeInactivityClient"
                                      full-width></v-time-picker>
                                  </v-menu>
                                </div>
                              </div>
                              <div class="tw-flex tw-items-center tw-p-4 tw-border tw-rounded-xl tw-mt-5 tw-max-h-20">
                                <div class="tw-text-sm tw-flex tw-items-center tw-gap-1">
                                  Tempo inatividade agente
                                  <v-tooltip bottom max-width="200">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <info-icon iconColor="#515151" iconSize="14px" />
                                      </div>
                                    </template>
                                    <span class="font-tooltip">Defina, em horas/minutos, o tempo limite
                                      de inatividade (por parte do agente) para
                                      que o atendimento seja finalizado. Para
                                      desativar, preencha 0.</span>
                                  </v-tooltip>
                                </div>
                                <div class="time-inactivity-client tw-ml-auto">
                                  <v-menu ref="menu" v-model="menuInactivyAgent" :close-on-content-click="false"
                                    :nudge-right="40" transition="scale-transition" offset-y max-width="290px"
                                    min-width="290px">
                                    <!-- :return-value.sync="time" -->
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field label="Informe o Horário" v-model="timeInactivityAgent"
                                        prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                                        v-on="on"></v-text-field>
                                    </template>
                                    <v-time-picker format="24hr" v-if="menuInactivyAgent" v-model="timeInactivityAgent"
                                      full-width></v-time-picker>
                                  </v-menu>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </v-form>
                      </template>
                    </div>
                    <div class="mt-6">
                      <template>
                        <v-form>
                          <fieldset class="fieldset-input tw-border tw-rounded-md tw-p-5">
                            <legend class="tw-font-semibold">
                              Horário de atendimento
                            </legend>
                            <div>
                              <div class="tw-flex tw-items-center tw--mt-8">
                                <div class="tw-text-sm tw-mt-10 tw-flex tw-items-center tw-gap-1">
                                  Horário de atendimento
                                  <v-tooltip bottom max-width="200">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <info-icon iconColor="#515151" iconSize="14px" />
                                      </div>
                                    </template>
                                    <span class="font-tooltip">Define o horario de atendimento da
                                      empresa</span>
                                  </v-tooltip>
                                </div>
                                <div class="welcome-message tw-ml-auto tw--mr-3 tw-px-5">
                                  <template>
                                    <v-row>
                                      <v-switch v-model="openingHoursIsActives" hide-details="auto"></v-switch>
                                    </v-row>
                                  </template>
                                </div>
                              </div>
                            </div>
                            <div v-if="openingHoursIsActives">
                              <div class="tw-flex tw-items-center">
                                <div class="tw-text-sm tw-flex tw-items-center tw-gap-1">
                                  Mensagem de ausência
                                  <v-tooltip bottom max-width="200">
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on">
                                        <info-icon iconColor="#515151" iconSize="14px" />
                                      </div>
                                    </template>
                                    <span class="font-tooltip">Mensagem que sera enviada quando o
                                      atendimento estiver fora do horário
                                      disponível.</span>
                                  </v-tooltip>
                                </div>
                                <div class="welcome-message tw-ml-auto tw--mr-3">
                                  <template>
                                    <v-container fluid>
                                      <v-textarea no-resize clearable outlined hide-details="auto"
                                        clear-icon="mdi-close-circle"></v-textarea>
                                    </v-container>
                                  </template>
                                </div>
                              </div>
                              <div class="tw-flex tw-items-center">
                                <div class="tw--mb-5 tw-text-sm">
                                  Motivo de Finalização (opcional)
                                </div>
                                <div class="tw-ml-auto select-input">
                                  <v-select hide-details dense outlined :items="finishedChatReasons"
                                    item-text="name"></v-select>
                                </div>
                              </div>
                              <div class="tw-flex tw-items-center">
                                <div class="tw--mb-5 tw-text-sm">
                                  Adicionar Horário
                                </div>
                                <div class="tw-ml-auto tw-flex tw-items-center tw-gap-4 tw-mr-2">
                                  <div class="add-hour select-input">
                                    <v-select hide-details dense outlined :items="week"
                                      v-model="attendenceDay"></v-select>
                                  </div>
                                  <div class="time-start tw-ml-auto">
                                    <v-menu ref="menu" v-model="menuStartTime" :close-on-content-click="false"
                                      :nudge-right="40" transition="scale-transition" offset-y max-width="290px"
                                      min-width="290px">
                                      <!-- :return-value.sync="time" -->
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Início" v-model="attendenceStartHour"
                                          prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                                          v-on="on"></v-text-field>
                                      </template>
                                      <v-time-picker format="24hr" v-if="menuStartTime" v-model="attendenceStartHour"
                                        full-width></v-time-picker>
                                    </v-menu>
                                  </div>
                                  <div class="time-start tw-ml-auto">
                                    <v-menu ref="menu" v-model="menuEndTime" :close-on-content-click="false"
                                      :nudge-right="40" transition="scale-transition" offset-y max-width="290px"
                                      min-width="290px">
                                      <!-- :return-value.sync="time" -->
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field label="Final" v-model="attendenceEndHour"
                                          prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                                          v-on="on"></v-text-field>
                                      </template>
                                      <v-time-picker format="24hr" v-if="menuEndTime" v-model="attendenceEndHour"
                                        full-width></v-time-picker>
                                    </v-menu>
                                  </div>
                                  <div class="tw-mt-5">
                                    <v-btn class="btn-add tw-rounded-lg" elevation="0" color="#007aff" @click="
                                      addHour(
                                        attendenceDay,
                                        attendenceStartHour,
                                        attendenceEndHour
                                      )
                                      ">
                                      <add-icon iconColor="#ffffff" iconSize="28px" />
                                    </v-btn>
                                  </div>
                                </div>
                              </div>
                              <div class="tw-mt-10 tw-px-4">
                                <div class="subtitle tw-ml-4">
                                  Tabela de Horários
                                </div>
                                <v-data-table :headers="tableAttendenceHourHeader" :items="tableAttendenceHour"
                                  hide-default-footer v-click-outside-custom="cancelChange"
                                  no-data-text="Nenhum resultado encontrado" disable-pagination
                                  class="tw-border tw-rounded-lg">
                                  <template v-slot:item.trash="{ item }">
                                    <div class="tw-ml-1">
                                      <v-btn class="delete-btn tw-rounded-full">
                                        <trash-icon iconColor="#515151" iconSize="20px" />
                                      </v-btn>
                                    </div>
                                  </template>
                                </v-data-table>
                              </div>
                            </div>
                          </fieldset>
                        </v-form>
                      </template>
                    </div>
                  </div>
                </div>
                <div v-if="index == 0"><!--index == 1-->
                  <div class="tw-flex tw-items-center">
                    <div @click="teste()" class="menuTitle">
                      {{ option }}
                    </div>
                    <div class="tw-ml-auto tw-flex tw-items-center">
                      <div class="tw--mb-3">
                        <v-text-field outlined placeholder="Pesquisar" append-icon="mdi-magnify"
                          v-model="findDepartment"></v-text-field>
                      </div>
                      <v-btn depressed dark color="#177dff" @click="selectDepartament">
                        <div class="tw-text-base">Adicionar Departamento</div>
                      </v-btn>
                    </div>
                  </div>
                  <div>
                    <div class="tw-py-2">
                      <v-data-table :headers="tableDepartamentHeader" :items="departmentsFilter()" hide-default-footer
                        v-click-outside-custom="cancelChange" no-data-text="Nenhum resultado encontrado"
                        disable-pagination @click:row="selectDepartament"
                        class="tw-border tw-rounded-lg overflow-table custom-scroll">
                        <template v-slot:item.status="{ item }">
                          <div class="tw--mt-7 tw-pb-3">
                            <v-switch @click.stop="changeDepartmentStatus(item)" v-model="item.status"
                              hide-details="auto"></v-switch>
                          </div>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <div class="tw-flex tw-gap-2">
                            <div class="tw--ml-4">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-bind="attrs" v-on="on" @click.stop="
                                    DeletedShortcut(item, 'o departamento')
                                    " class="delete-btn tw-rounded-full">
                                    <trash-icon iconColor="#515151" iconSize="20px" />
                                  </v-btn>
                                </template>
                                <span>Excluir</span>
                              </v-tooltip>
                            </div>
                            <div>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-bind="attrs" v-on="on" @click.stop="selectDepartament(item)"
                                    class="delete-btn tw-rounded-full">
                                    <edit-icon iconColor="#515151" iconSize="20px" />
                                  </v-btn>
                                </template>
                                <span>Editar</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </div>
                <div v-if="index == 1"><!--index == 2-->
                  <div class="users-height tw-flex tw-items-center">
                    <div class="menuTitle">
                      {{ option }}
                    </div>
                    <div class="tw-ml-auto">
                      <v-text-field outlined placeholder="Pesquisar em usuários" append-icon="mdi-magnify"
                        v-model="findChatUser"></v-text-field>
                    </div>
                  </div>
                  <div class="tw-py-2">
                    <v-data-table :headers="usersChatHeaders" :items="usersChatFilter(users)" hide-default-footer
                      v-click-outside-custom="cancelChange" no-data-text="Nenhum resultado encontrado" disable-pagination
                      @click:row="selectUser" class="tw-border tw-rounded-lg overflow-table custom-scroll">
                      <template v-slot:[`item.photoKey`]="{ item }">
                        <div class="tw-ml-5">
                          <UserPhoto size="32" width="32" height="32" :user="item" />
                        </div>
                      </template>
                      <template v-slot:[`item.statusWhats`]="{ item }">
                        <div class="tw--mt-8 tw-ml-5 tw-mb-2">
                          <v-switch @click.stop="changeStatusWhats(item)" v-model="item.statusWhats"
                            hide-details="auto"></v-switch>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div v-if="index == -1"><!--index == 3-->
                  <div class="tw-flex tw-items-center">
                    <div class="menuTitle">
                      {{ option }}
                    </div>
                    <div class="tw-ml-auto tw-flex tw-items-center">
                      <div class="tw--mb-3">
                        <v-text-field outlined placeholder="Pesquisar" append-icon="mdi-magnify"
                          v-model="findShortcut"></v-text-field>
                      </div>
                      <v-btn depressed dark color="#177dff" @click="selectShortcut">
                        <span class="tw-text-base">Adicionar Atalho</span>
                      </v-btn>
                    </div>
                  </div>
                  <div>
                    <div class="tw-py-2">
                      <v-data-table :headers="tableShortcutHeader" :items="shortcutFilter(shortcuts)" hide-default-footer
                        return-object @click:row="selectShortcut" v-click-outside-custom="cancelChange"
                        no-data-text="Nenhum resultado encontrado" disable-pagination
                        class="tw-border tw-rounded-lg overflow-table custom-scroll">
                        <template v-slot:item.status="{ item }">
                          <div class="tw--mt-7 tw-pb-3">
                            <v-switch @click.stop="changeShortcutStatus(item)" v-model="item.status"
                              hide-details="auto"></v-switch>
                          </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                          <div class="tw-flex tw-gap-2">
                            <div class="tw--ml-4">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-bind="attrs" v-on="on" @click.stop="
                                    DeletedShortcut(item, 'o atalho')
                                    " class="delete-btn tw-rounded-full">
                                    <trash-icon iconColor="#515151" iconSize="20px" />
                                  </v-btn>
                                </template>
                                <span>Excluir</span>
                              </v-tooltip>
                            </div>
                            <div>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-bind="attrs" v-on="on" @click.stop="selectShortcut(item)"
                                    class="delete-btn tw-rounded-full">
                                    <edit-icon iconColor="#515151" iconSize="20px" />
                                  </v-btn>
                                </template>
                                <span>Editar</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </div>

                <div v-if="index == 2"><!--index == 4-->
                  <!--TAGS-->
                  <div class="tw-flex tw-items-center">
                    <div class="menuTitle">
                      {{ option }}
                    </div>
                    <div class="tw-ml-auto tw-flex tw-items-center">
                      <div class="tw--mb-3">
                        <v-text-field outlined placeholder="Pesquisar" append-icon="mdi-magnify"
                          v-model="findTag"></v-text-field>
                      </div>
                      <v-btn depressed dark color="#177dff" @click="selectTag">
                        <div class="tw-text-base">Adicionar Etiqueta</div>
                      </v-btn>
                    </div>
                  </div>
                  <div class="tw-py-2">
                    <v-data-table :headers="tagsHeader" :items="tagsFilter(tags)" hide-default-footer
                      v-click-outside-custom="cancelChange" no-data-text="Nenhum resultado encontrado" disable-pagination
                      @click:row="selectTag" class="tw-border tw-rounded-lg overflow-table custom-scroll">
                      <template v-slot:item.color="{ item }">
                        <label-icon :iconColor="item.color" iconSize="30px" />
                      </template>
                      <template v-slot:item.status="{ item }">
                        <div class="tw--mt-7 tw-pb-3">
                          <v-switch @click.stop="changeTagStatus(item)" v-model="item.status"
                            hide-details="auto"></v-switch>
                        </div>
                      </template>
                      <template v-slot:item.action="{ item }">
                        <div class="tw-flex tw-gap-2">
                          <div class="tw--ml-4">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click.stop="
                                  DeletedShortcut(item, 'a etiqueta')
                                  " class="delete-btn tw-rounded-full">
                                  <trash-icon iconColor="#515151" iconSize="20px" />
                                </v-btn>
                              </template>
                              <span>Excluir</span>
                            </v-tooltip>
                          </div>
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click.stop="selectTag(item)"
                                  class="delete-btn tw-rounded-full">
                                  <edit-icon iconColor="#515151" iconSize="20px" />
                                </v-btn>
                              </template>
                              <span>Editar</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </div>

                <div v-if="index == 3"><!--index == 5-->
                  <!--Motivos Finalização-->
                  <div class="tw-flex tw-items-center">
                    <div class="menuTitle">
                      {{ option }}
                    </div>
                    <div class="tw-ml-auto tw-flex tw-items-center">
                      <div class="tw--mb-3">
                        <v-text-field outlined placeholder="Pesquisar" append-icon="mdi-magnify"
                          v-model="findMotive"></v-text-field>
                      </div>
                      <v-btn depressed dark color="#177dff" @click="selectMotiveFinished">
                        <div class="tw-text-base">Adicionar</div>
                      </v-btn>
                    </div>
                  </div>
                  <div class="tw-py-2">
                    <v-data-table :headers="motivesFinishedHeader" :items="reasonsFilter(finishedChatReasons)"
                      hide-default-footer v-click-outside-custom="cancelChange" no-data-text="Nenhum resultado encontrado"
                      disable-pagination class="tw-border tw-rounded-lg overflow-table custom-scroll"
                      @click:row="selectMotiveFinished">
                      <template v-slot:item.status="{ item }">
                        <div class="tw--mt-7 tw-pb-3">
                          <v-switch @click.stop="changeFinishedChatReasonStatus(item)" v-model="item.status"
                            hide-details="auto"></v-switch>
                        </div>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <div class="tw-flex tw-gap-2">
                          <div class="tw--ml-4">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click.stop="
                                  DeletedShortcut(
                                    item,
                                    'o motivo de finalização'
                                  )
                                  " class="delete-btn tw-rounded-full">
                                  <trash-icon iconColor="#515151" iconSize="20px" />
                                </v-btn>
                              </template>
                              <span>Excluir</span>
                            </v-tooltip>
                          </div>
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click.stop="selectMotiveFinished(item)"
                                  class="delete-btn tw-rounded-full">
                                  <edit-icon iconColor="#515151" iconSize="20px" />
                                </v-btn>
                              </template>
                              <span>Editar</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div v-if="index == 4"><!--index == 6-->
                  <div class="tw-flex tw-items-center tw-h-9">
                    <div class="menuTitle">
                      {{ option }}
                    </div>
                    <div v-if="loggedUser.isChatBotActive" class="tw-ml-auto tw-flex tw-content-center tw-items-center">
                      <div class="tw--mb-3">
                        <v-text-field outlined placeholder="Pesquisar" append-icon="mdi-magnify"
                          v-model="findMenu"></v-text-field>
                      </div>
                      <v-btn depressed dark color="#177dff" @click="selectMenuChatBot()">
                        <div class="tw-text-base">Adicionar Menu</div>
                      </v-btn>
                    </div>
                  </div>
                  <div class="tw-p-5">
                    <div class="tw-flex tw-items-center">
                      <div class="tw-flex tw-items-center">
                        <div class="tw-text-sm tw-w-28">Ativar ChatBot</div>
                        <v-tooltip bottom max-width="200">
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <info-icon iconColor="#515151" iconSize="14px" />
                            </div>
                          </template>
                          <span class="font-tooltip">Ativar CahtBot para atendimento automático</span>
                        </v-tooltip>
                      </div>
                      <div>
                        <v-switch @change="changeChatBot()" class="tw-p-1 tw--mt-5" hide-details="auto"
                          v-model="loggedUser.isChatBotActive"></v-switch>
                      </div>
                    </div>

                    <v-data-table v-if="loggedUser.isChatBotActive" :headers="menusChatBotHeader"
                      :items="menusChatBotFilter(menusChatBot)" hide-default-footer v-click-outside-custom="cancelChange"
                      no-data-text="Nenhum resultado encontrado" disable-pagination
                      class="tw-border tw-rounded-lg overflow-table custom-scroll" @click:row="selectMenuChatBot">
                      <template v-slot:item.status="{ item }">
                        <div class="tw--mt-7 tw-pb-3">
                          <v-switch @click.stop="changeMenuChatBotStatus(item)" v-model="item.status"
                            hide-details="auto"></v-switch>
                        </div>
                      </template>
                      <template v-slot:item.action="{ item }">
                        <div class="tw-flex tw-gap-2">
                          <div class="tw--ml-4">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click.stop="
                                  DeletedShortcut(
                                    item,
                                    'o menu'
                                  )
                                  " class="delete-btn tw-rounded-full">
                                  <trash-icon iconColor="#515151" iconSize="20px" />
                                </v-btn>
                              </template>
                              <span>Excluir</span>
                            </v-tooltip>
                          </div>
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" @click.stop="selectMenuChatBot(item)"
                                  class="delete-btn tw-rounded-full">
                                  <edit-icon iconColor="#515151" iconSize="20px" />
                                </v-btn>
                              </template>
                              <span>Editar</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </template>
                    </v-data-table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <CreateNewUser v-model="newUserModal" v-if="newUserModal" :hasPermission="true" :selectedUser="selectedUser"
      @close="closeModal" @saveNewUser="saveNewUser" @editUser="changeUser" @excludeUser="excludeUser" />
    <CreateNewEnvironment v-model="newEnvironmentModal" v-if="newEnvironmentModal" :hasPermission="true"
      :selectedEnvironment="selectedEnvironment" @close="closeModal" @saveNewEnvironment="saveNewEnvironment"
      @editEnvironment="changeEnvironment" @excludeEnvironment="excludeEnvironment" />
    <CreateNewTag v-model="modelDialogNewTag" v-if="modelDialogNewTag" :selectedTag="selectedTag" @close="closeModal" />
    <CreateReasconChat v-model="modelAddMotiveFinished" v-if="modelAddMotiveFinished" :selectedMotive="selectedMotive"
      @close="closeModal" />
    <AddDepartament v-model="modelDialogAddDepartament" v-if="modelDialogAddDepartament"
      :selectedDepartment="selectedDepartment" @close="closeModal" />
    <AddShortcut v-model="modelDialogAddShortcut" v-if="modelDialogAddShortcut" @close="closeModal"
      :selectedShortcut="selectedShortcut" />
    <AddChatBot v-model="modelDialogChatBot" v-if="modelDialogChatBot" :selectedMenuChatBot="selectedMenuChatBot"
      :optionsMenuChatBot="selectedOptionsMenu" @close="closeModal" />
    <DeleteShortcut v-model="modelDialogDeleteShortcut" v-if="modelDialogDeleteShortcut"
      :selectedShortcut="selectedShortcut" :selectedTitle="selectedTitle" @close="closeModal" />
    <QrCodeConnectionVue v-model="modelDialogQrcode" v-if="modelDialogQrcode" :qrcode="qrcode" @close="closeModal" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState: mapStateAttendences, mapActions: mapActionsAttendences } =
  createNamespacedHelpers('attendences');
const { mapActions: mapActionsConfig, mapState: mapStateConfig } =
  createNamespacedHelpers('config');

import CreateNewUser from '../modals/CreateNewUser';

import UserPhoto from '../../../components/utils/UserPhoto.vue';

import CreateNewEnvironment from '../modals/CreateNewEnvironment';

import CreateNewTag from './../modals/CreateNewTag';

import CreateReasconChat from './../modals/CreateReasconChat.vue';

import AddDepartament from '../modals/AddDepartament.vue';

import AddShortcut from '../modals/AddShortcut.vue';

import AddChatBot from '../modals/AddChatBot.vue';

import DeleteShortcut from '../modals/DeleteShortcut.vue';

import QrCodeConnectionVue from '../modals/QrCodeConnection.vue';

export default {
  components: {
    CreateNewUser,
    UserPhoto,
    CreateNewEnvironment,
    CreateNewTag,
    CreateReasconChat,
    AddDepartament,
    AddShortcut,
    AddChatBot,
    DeleteShortcut,
    QrCodeConnectionVue,
  },
  data() {
    return {
      menuEndTime: false,
      menuStartTime: false,
      menuInactivyClient: false,
      menuInactivyAgent: false,
      timeInactivityClient: '00:00',
      timeInactivityAgent: '00:00',
      attendenceDay: '',
      attendenceStartHour: '00:00',
      attendenceEndHour: '00:00',
      openingHoursIsActives: true,
      modelDialogNewTag: false,
      modelDialogChatBot: false,
      modelDialogDeleteShortcut: false,
      modelDialogQrcode: false,
      modelDialogAddDepartament: false,
      modelDialogAddShortcut: false,
      modelAddMotiveFinished: false,
      isLoaderActive: false,
      newEnvironmentModal: false,
      tab: 0,
      qrcode: "",
      selectOption: '',
      selectedTitle: '',
      selectedUser: null,
      selectedDepartment: null,
      selectedMotive: null,
      selectedMenuChatBot: null,
      selectedOptionsMenu: [],
      selectedEnvironment: null,
      selectedTag: null,
      selectedShortcut: null,
      editingRow: {},
      week: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
      ],
      menuOptions: [
        // 'Ajustes Gerais',
        'Departamentos',
        'Usuários',
        // 'Atalhos',
        'Etiquetas',
        'Motivos de Finalização',
        'ChatBot',
      ],
      tableDepartamentHeader: [
        {
          text: 'Nome',
          aligin: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ],
      tableShortcutHeader: [
        {
          text: 'Descrição',
          aligin: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Atalho',
          value: 'shortcut',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Ações',
          value: 'action',
        },
      ],
      tableAttendenceHourHeader: [
        {
          text: 'Dia da Semana',
          aligin: 'start',
          sortable: false,
          value: 'day',
          width: '300px',
        },
        {
          text: 'Início',
          value: 'start',
        },
        {
          text: 'Final',
          value: 'end',
        },
        {
          text: 'Excluir',
          value: 'trash',
          width: '150px',
        },
      ],
      tableAttendenceHour: [
        {
          day: 'Segunda',
          start: '00:00',
          end: '00:01',
        },
      ],
      motivesFinishedHeader: [
        {
          text: 'Título',
          aligin: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Ações',
          value: 'actions',
        },
      ],
      menusChatBotHeader: [
        {
          text: 'Identificador',
          align: 'start',
          sortable: false,
          value: 'identifier',
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Ação',
          value: 'action'
        }
      ],
      tagsHeader: [
        {
          text: 'Nome',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Cor',
          value: 'color',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Ação',
          value: 'action',
        },
      ],
      tabItemsTemp: [
        {
          id: 1,
          title: 'Usuários',
          permission: null,
        },
        {
          id: 2,
          title: 'Status',
          permission: null,
        },
        {
          id: 3,
          title: 'Ambientes',
          permission: null,
        },
        {
          id: 4,
          title: 'Chat',
          permission: 'edit_chat',
        },
      ],
      tabItems: ['Usuários', 'Status', 'Ambientes', 'Chat'],
      statusesHeaders: [
        { text: 'Nome do status', value: 'name', width: '', sortable: false },
        {
          text: 'Prazo para alerta',
          value: 'alertTime',
          sortable: false,
        },
      ],
      usersHeaders: [
        { text: 'Nome do usuário', value: 'name' },
        { text: 'Tipo de usuário', value: 'role.name' },
        { text: 'E-mail', value: 'email' },
        { text: 'Foto de perfil', value: 'photoKey' },
      ],
      usersChatHeaders: [
        { text: 'Nome do usuário', value: 'name' },
        { text: 'Foto de perfil', value: 'photoKey' },
        { text: 'Ativar Usuário', value: 'statusWhats' },
      ],
      environmentsHeaders: [
        { text: 'Ambiente', value: 'name' },
        { text: 'Margem de lucro', value: 'margin' },
      ],
      newUserModal: null,
      userArray: [],
      findUser: '',
      findChatUser: '',
      findDepartment: '',
      findShortcut: '',
      findTag: '',
      findMenu: '',
      findMotive: '',
    };
  },
  created() {
  },
  watch: {
  },
  methods: {
    ...mapActionsConfig([
      'createUser',
      'editUser',
      'deleteUser',
      'deleteTag',
      'editTag',
      'editShortcut',
      'editFinishedChatReason',
      'editDepartment',
      'aleterChatBot',
      'alterStatusWhats',
      'getMenusChatBot',
      'editMenuChatBot',
    ]),
    ...mapActionsAttendences([
      'editStatus',
      'createAttendenceItemEnvironments',
      'editAttendenceItemEnvironments',
      'deleteAttendenceItemEnvironments',
    ]),
    changeChatBot() {
      this.aleterChatBot(this.loggedUser.id);
    },
    async changeStatusWhats(user) {
      user.statusWhats = !user.statusWhats;
      await this.alterStatusWhats(user.id);
    },
    qrGenerator() {
      this.modelDialogQrcode = true;
    },
    async excludeEnvironment(e) {
      await this.deleteAttendenceItemEnvironments({ environmentId: e.id });
      this.newEnvironmentModal = false;
    },
    async changeEnvironment(e) {
      await this.editAttendenceItemEnvironments(e);
      this.newEnvironmentModal = false;
    },
    selectEnvironment(e) {
      this.selectedEnvironment = e;
      this.newEnvironmentModal = true;
    },
    async excludeUser(e) {
      await this.deleteUser({ userId: e });
      this.newUserModal = false;
    },
    async saveNewEnvironment(e) {
      await this.createAttendenceItemEnvironments(e);
      this.newEnvironmentModal = false;
    },
    hasPermission(permissionName) {
      if (permissionName && this.loggedUser.role) {
        const has = this.loggedUser.role.permissions.find((el) => {
          return el.permission == permissionName ? true : false;
        });
        if (has) {
          return true;
        }
        return false;
      }
      return true;
    },
    closeModal() {
      this.newUserModal = false;
      this.newEnvironmentModal = false;
      this.selectedUser = null;
      this.selectedDepartment = null;
      this.selectedMotive = null;
      this.selectedMenuChatBot = null;
      this.selectedTag = null;
      this.selectedShortcut = null;
      this.selectedTitle = '';
      this.modelDialogNewTag = false;
      this.modelDialogAddDepartament = false;
      this.modelAddMotiveFinished = false;
      this.modelDialogAddShortcut = false;
      this.modelDialogChatBot = false;
      this.modelDialogDeleteShortcut = false;
      this.modelDialogQrcode = false;
    },
    DeletedShortcut(item, title) {
      this.selectedShortcut = item;
      this.selectedTitle = title;
      this.modelDialogDeleteShortcut = true;
    },
    selectUser(event) {
      this.selectedUser = event;
      this.newUserModal = true;
    },
    selectDepartament(event) {
      this.selectedDepartment = event;
      this.modelDialogAddDepartament = true;
    },
    selectTag(event) {
      this.selectedTag = event;
      this.modelDialogNewTag = true;
    },
    selectShortcut(event) {
      this.selectedShortcut = event;
      this.modelDialogAddShortcut = true;
    },
    selectMotiveFinished(event) {
      this.selectedMotive = event;
      this.modelAddMotiveFinished = true;
    },
    selectMenuChatBot(event) {
      this.selectedOptionsMenu = this.optionsChatBot.filter((item) => item.menuId == event?.id);
      this.selectedMenuChatBot = event;
      this.modelDialogChatBot = true;
    },
    async saveNewUser(event) {
      let dataForm = new FormData();
      dataForm.append('file', event.imageFile[0]);
      Object.keys(event.user).forEach((key) => {
        if (!(event.user[key] == null) && !(event.user[key] == undefined)) {
          dataForm.append(key, event.user[key]);
        }
      });
      await this.createUser({ user: dataForm, role: event.role });
      this.newUserModal = false;
    },
    async changeUser(event) {
      let dataForm = new FormData();
      dataForm.append('file', event.imageFile[0]);
      Object.keys(event.user).forEach((key) => {
        if (!(event.user[key] == null) && !(event.user[key] == undefined)) {
          dataForm.append(key, event.user[key]);
        }
      });
      await this.editUser({ user: dataForm });
      this.newUserModal = false;
    },
    rowClicked(event) {
      this.editingRow = JSON.parse(JSON.stringify(event));
    },
    async confirmChange(event) {
      this.isLoaderActive = true;
      await this.editStatus({ status: this.editingRow });
      this.editingRow = {};
      this.isLoaderActive = false;
    },
    cancelChange() {
      this.editingRow = {};
    },
    UsersFilter(filterUsers) {
      let array = [];
      array = filterUsers.filter((el) =>
        el.name.toLowerCase().includes(this.findUser.toLowerCase())
      );
      if (!array.length) {
        array = filterUsers.filter((el) =>
          el.email.toLowerCase().includes(this.findUser.toLowerCase())
        );
      }
      return array;
    },
    usersChatFilter(filterUsers) {
      let array = [];
      array = filterUsers.filter((el) =>
        el.name.toLowerCase().includes(this.findChatUser.toLowerCase())
      );
      return array;
    },
    departmentsFilter() {
      let array = [];
      array = this.departments.filter((el) => {
        return el.name.toLowerCase().includes(this.findDepartment.toLowerCase())
      });
      return array;
    },
    shortcutFilter(filterShortcuts) {
      let array = [];
      array = filterShortcuts.filter((el) =>
        el.name.toLowerCase().includes(this.findShortcut.toLowerCase())
      );
      return array;
    },
    tagsFilter(filterTags) {
      let array = [];
      array = filterTags.filter((el) =>
        el.name.toLowerCase().includes(this.findTag.toLowerCase())
      );
      return array;
    },
    reasonsFilter(filterReasons) {
      let array = [];
      array = filterReasons.filter((el) =>
        el.name.toLowerCase().includes(this.findMotive.toLowerCase())
      );
      return array;
    },
    menusChatBotFilter(filterMenus) {
      let array = [];
      array = filterMenus.filter((el) => el.identifier.toLowerCase().includes(this.findMenu.toLowerCase()));
      return array
    },
    changeOption(option) {
      this.selectOption = option;
    },
    changeDepartmentStatus(department) {
      this.editDepartment({ department: department });
    },
    changeTagStatus(tag) {
      this.editTag({ tag: tag });
    },
    changeShortcutStatus(shortcut) {
      this.editShortcut({ shortcut: shortcut });
    },
    changeFinishedChatReasonStatus(finishedChatReason) {
      this.editFinishedChatReason({ finishedChatReason: finishedChatReason });
    },
    changeMenuChatBotStatus(menuChatBot) {
      this.editMenuChatBot(menuChatBot);
    },
    addHour(day, startHour, endHour) {
      this.tableAttendenceHour.push({
        day: day,
        start: startHour,
        end: endHour,
      });
    },
    async excludeTag(id) {
      await this.deleteTag({ tagId: id });
    },
  },
  watch: {
    users(newVal) {
      this.userArray = newVal;
    },
  },
  computed: {
    ...mapStateAttendences(['statuses', 'environments']),
    ...mapStateConfig([
      'users',
      'tags',
      'shortcuts',
      'finishedChatReasons',
      'departments',
      'loggedUser',
      'menusChatBot',
      'optionsChatBot',
    ]),
    returnUsers() {
      return [this.users[this.users.length - 1]];
    },
  },
};
</script>

<style>
.configurations-div .v-data-table tbody tr:nth-child(odd) {
  background-color: #f8fafc;
}

.configurations-div .v-data-table td,
.configurations-div .v-data-table th {
  padding-left: 30px !important;
}

.configurations-div {
  /* width: 100%; */
  /* width: 100; */
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* max-height: calc(100vh - 60px); */
  height: 100%;
  padding-bottom: 36px;
}

.configurations-div .configurations-content {
  background-color: white;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
  flex-flow: column;
  display: flex;
}

.configurations-div .configurations-content .v-tabs {
  padding: 10px 20px;
}

.configurations-div .configurations-content .v-tabs-items {
  /* overflow-y: auto; */
  overflow-x: hidden;
}

.configurations-div .tab-title {
  font-size: 30px;
  font-weight: 600;
  padding-top: 20px;
  padding-left: 30px;
}

.configurations-div .v-input__slot {
  width: 240px;
  margin-top: 20px;
}

.configurations-div .v-input {
  flex: none;
}

.configurations-div .v-input__control {
  margin-right: 7px;
}

.configurations-div .v-text-field--outlined>.v-input__control>.v-input__slot {
  min-height: 36px;
  max-height: 36px;
}

.configurations-div .v-text-field--enclosed .v-input__append-inner {
  margin-top: 7px;
}

.left-menu {
  width: 350px;
}

.left-menu .list-menu {
  background-color: #ebeef7 !important;
  border-radius: 20px;
}

.optionHover:hover {
  transform: scale(1.03);
  background-color: #f0f0f0;
}

.primaryColor {
  transition: 0.15s;
  color: #007aff;
}

.menuTitle {
  font-size: 20px;
  padding: 0px 10px;
  font-weight: 600;
  color: #515151;
}

.tagList {
  margin-left: 50%;
}

legend {
  font-size: 18px;
  color: #007aff;
}

.fieldset-input .v-input__slot {
  width: 400px !important;
}

.welcome-message .v-input__slot {
  min-height: 100px !important;
  max-height: 100px !important;
}

.finished-inactivity input {
  outline: none;
}

.select-input input {
  display: none;
}

.add-hour .v-input__slot {
  width: 150px !important;
}

.btn-add {
  min-width: 40px !important;
  width: 40px;
  height: 40px;
}

.overflow-height {
  overflow-y: scroll;
  height: 400px;
}

.time-inactivity-client .v-input__slot {
  width: 200px !important;
}

.time-inactivity-client .mdi-clock-time-four-outline::before {
  margin-bottom: -40px;
}

.time-start .mdi-clock-time-four-outline::before {
  margin-bottom: -40px;
}

.time-start .v-input__slot {
  width: 50px !important;
}

.time-start,
.v-label,
.v-label--active {
  font-size: 18px !important;
}

.delete-btn {
  min-width: 30px !important;
  width: 30px;
  height: 30px;
  max-height: 30px;
}

.font-tooltip {
  font-size: 12px;
}

.users-height {
  max-height: 36px;
}

.overflow-table {
  overflow-y: scroll;
  max-height: 48vh;
  /* padding-bottom: 10px; */
  margin-bottom: 1px;
}

.v-tabs {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: none !important;
}
</style>
