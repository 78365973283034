<template>
  <v-dialog
    width="40%"
    height="70%"
    persistent
    v-model="show"
    @click:outside="closeDialog"
    scrollable
  >
    <div class="tw-bg-white tw-p-4">
      <div class="tw-flex tw-py-2 tw-items-center">
        <div class="tw-flex tw-items-center tw-gap-1">
          <div class="tw-text-2xl" v-if="localSelectedShortcut.id">
            Editar Atalho
          </div>
          <div class="tw-text-2xl" v-else>Adicionar Atalho</div>
          <v-tooltip bottom max-width="200">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <info-icon
                  iconColor="#515151"
                  iconSize="14px"
                  class="tw--mb-1"
                />
              </div>
            </template>
            <span class="font-tooltip">Respostas Rápidas</span>
          </v-tooltip>
        </div>
        <div class="tw-ml-auto tw-mr-2">
          <v-btn
            elevation="0"
            color="#fff"
            class="close-btn tw-rounded-full"
            @click="closeDialog"
          >
            <close-icon fillColor="#515151" size="28px" />
          </v-btn>
        </div>
      </div>
      <div>
        <div class="tw-px-3 tw-py-2">
          <div class="tw-flex tw-items-center tw-gap-1">
            <div class="subtitle">Atalho</div>
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <info-icon iconColor="#515151" iconSize="14px" />
                </div>
              </template>
              <span class="font-tooltip"
                >Identificação para inserir o atalho no chat</span
              >
            </v-tooltip>
          </div>
          <v-text-field
            v-if="localSelectedShortcut.id"
            v-model="newShortcuts"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
          <v-text-field
            v-else
            outlined
            v-model="newShortcuts"
            dense
            hide-details="auto"
          ></v-text-field>
          <span
            v-if="
              (!createValidation && !newShortcuts) ||
              (!editValidation && !newShortcuts)
            "
            class="tw-text-xs tw-text-red-500"
          >
            Você deve informar o identificador do atalho
          </span>
        </div>
      </div>
      <div>
        <div>
          <div class="tw-px-3 tw-py-2">
            <div class="tw-flex tw-items-center tw-gap-1">
              <div class="subtitle">Nome</div>
              <v-tooltip bottom max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <info-icon iconColor="#515151" iconSize="14px" />
                  </div>
                </template>
                <span class="font-tooltip"
                  >Título do atalho para identifição em sua lista de
                  atalhos</span
                >
              </v-tooltip>
            </div>
            <v-text-field
              v-if="localSelectedShortcut.id"
              v-model="newName"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
            <v-text-field
              v-else
              outlined
              v-model="newName"
              dense
              hide-details="auto"
            ></v-text-field>
            <span
              v-if="
                (!createValidation && !newName) ||
                (!editValidation && !newName)
              "
              class="tw-text-xs tw-text-red-500"
            >
              Você deve informar o nome do atalho
            </span>
          </div>
        </div>
        <div>
          <div class="tw-px-3 tw-py-2">
            <div class="tw-flex tw-items-center tw-gap-1">
              <div class="subtitle">Complemento</div>
              <v-tooltip bottom max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <info-icon iconColor="#515151" iconSize="14px" />
                  </div>
                </template>
                <span class="font-tooltip">Anexos do atalho</span>
              </v-tooltip>
            </div>
            <v-select
              v-if="localSelectedShortcut.id"
              hide-details
              dense
              outlined
              :items="complements"
              v-model="newComplement"
            ></v-select>
            <v-select
              v-else
              hide-details
              dense
              v-model="newComplement"
              outlined
              :items="complements"
            ></v-select>
            <span
              v-if="
                (!createValidation && !newComplement) ||
                (!editValidation && !newComplement)
              "
              class="tw-text-xs tw-text-red-500"
            >
              Você deve informar o complemento do atalho
            </span>
          </div>
        </div>
        <div class="">
          <template>
            <v-container fluid>
              <div class="tw-flex tw-items-center tw-gap-1">
                <div class="subtitle">Mensagem de Resposta</div>
                <v-tooltip bottom max-width="200">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <info-icon iconColor="#515151" iconSize="14px" />
                    </div>
                  </template>
                  <span class="font-tooltip"
                    >Mensagem a ser enviada quando o atalho for
                    selecionado</span
                  >
                </v-tooltip>
              </div>
              <v-textarea
                v-if="localSelectedShortcut.id"
                v-model="newMessage"
                no-resize
                clearable
                outlined
                clear-icon="mdi-close-circle"
              ></v-textarea>
              <v-textarea
                v-else
                no-resize
                v-model="newMessage"
                clearable
                outlined
                clear-icon="mdi-close-circle"
              ></v-textarea>
              <span
                v-if="
                  (!createValidation && !newMessage) ||
                  (!editValidation && !newMessage)
                "
                class="tw-text-xs tw-text-red-500"
              >
                Você deve informar a mensagem do atalho
              </span>
            </v-container>
          </template>
        </div>
      </div>
      <div class="tw-w-56 tw-ml-auto">
        <v-btn dark depressed @click="createNewShortcut()" color="blue"
          >Confirmar</v-btn
        >
        <v-btn text color="blue" class="tw-ml-2" @click="$emit('close')"
          >Cancelar</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState: mapStateConfig, mapActions: mapActionsConfig } =
  createNamespacedHelpers('config');
export default {
  props: { value: Boolean, selectedShortcut: Object },
  data() {
    return {
      newShortcut: {},
      newName: this.selectedShortcut.name,
      newShortcuts: this.selectedShortcut.shortcut,
      newComplement: this.selectedShortcut.complement,
      newMessage: this.selectedShortcut.message,
      createValidation: true,
      editValidation: true,
      localSelectedShortcut: {},
      complement: false,
      complements: ['Nenhum', 'Imagem', 'Áudio', 'Documento', 'Localização'],
    };
  },
  watch: {
    selectedShortcut(val) {
      if (val) {
        this.localSelectedShortcut = { ...this.selectedShortcut };
      }
    },
  },
  methods: {
    ...mapActionsConfig(['createShortcut', 'editShortcut']),
    closeDialog() {
      this.$emit('close', false);
    },
    async createNewShortcut() {
      if (this.selectedShortcut.id) {
        if (
          this.newName &&
          this.newShortcuts &&
          this.newComplement &&
          this.newMessage
        ) {
          this.selectedShortcut.name = this.newName;
          this.selectedShortcut.shortcut = this.newShortcuts;
          this.selectedShortcut.complement = this.newComplement;
          this.selectedShortcut.message = this.newMessage;
          await this.editShortcut({ shortcut: this.selectedShortcut });
          this.editValidation = true;
          this.closeDialog();
        } else {
          this.editValidation = false;
        }
      } else {
        if (
          this.newName &&
          this.newShortcuts &&
          this.newComplement &&
          this.newMessage
        ) {
          this.newShortcut.name = this.newName;
          this.newShortcut.shortcut = this.newShortcuts;
          this.newShortcut.complement = this.newComplement;
          this.newShortcut.message = this.newMessage;
          await this.createShortcut({ shortcut: this.newShortcut });
          this.createValidation = true;
          this.closeDialog();
        } else {
          this.createValidation = false;
        }
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.subtitle {
  color: #949494;
}
.mdi-close-circle::before {
  margin-left: 16px;
}
</style>
