<template>
  <v-dialog
    max-width="900"
    v-model="show"
    @click:outside="closeDialog"
    scrollable
  >
    <v-card class="create-environment" :loading="loading">
      <v-card-title class="mb-5">
        <template v-if="!selectedEnvironment"> Novo Usuário </template>
        <template v-else> Editar usuário </template>

        <v-spacer></v-spacer>
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <ValidationObserver v-slot="v" ref="observer">
          <form @submit.prevent="v.handleSubmit(onSubmit)">
            <v-row>
              <v-col cols="7">
                <v-card-subtitle class="pl-0 pb-2 pt-0">Nome</v-card-subtitle>
                <ValidationProvider rules="isNotBlank" v-slot="v">
                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="environment.name"
                    :disabled="!hasPermission"
                  ></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <v-col cols="5">
                <v-card-subtitle class="pl-0 pb-2 pt-0"
                  >Margem de lucro*</v-card-subtitle
                >
                <ValidationProvider rules="isNotBlank" v-slot="v">
                  <v-text-field
                    dense
                    outlined
                    hide-details="auto"
                    v-model="environment.margin"
                    :disabled="!hasPermission"
                  ></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="pt-7 pb-8">
        <v-btn
          text
          v-if="selectedEnvironment && userHasPermission('change_item')"
          @click="modalDelete = true"
          ><v-icon>la-trash-alt</v-icon>Excluir ambiente</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn dark depressed color="blue" @click="validateForm()"
          >Confirmar</v-btn
        >
        <v-btn text color="blue" @click="$emit('close')">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmationModal
      v-if="modalDelete"
      v-model="modalDelete"
      message="Você quer mesmo deletar este ambiente?"
      @close="modalDelete = false"
      @confirmed="excludeEnvironment"
    />
  </v-dialog>
</template>

<script>
import ConfirmationModal from '../../../components/utils/ConfirmationModal.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapActions: mapActionsAttendences } =
  createNamespacedHelpers('attendences');

export default {
  props: {
    value: Boolean,
    selectedEnvironment: Object,
    hasPermission: Boolean,
  },
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      modalDelete: false,
      loading: false,
      environment: {},
      imageFile: [],
      dragging: false,
    };
  },
  methods: {
    async excludeEnvironment() {
      this.loading = true;
      this.$emit('excludeEnvironment', this.selectedEnvironment);
    },
    async validateForm() {
      const isValid = await this.$refs.observer.validate();
      if (this.$refs.observer._data.flags.valid) {
        this.saveNewClient();
      }
    },
    closeDialog() {
      this.$emit('close', false);
    },
    saveNewClient() {
      this.loading = true;
      if (!this.environment.id) {
        this.$emit('saveNewEnvironment', {
          environment: this.environment,
        });
      } else {
        this.$emit('editEnvironment', {
          environment: this.environment,
        });
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);   
      },
    },
  },
  mounted() {
    if (this.selectedEnvironment) {
      this.environment = { ...this.selectedEnvironment };
    }
  },
};
</script>
