<template>
  <v-dialog
    width="40%"
    height="70%"
    persistent
    v-model="show"
    @click:outside="closeDialog"
    scrollable
  >
    <div class="text-content tw-bg-white tw-p-6">
      <div class="tw-text-2xl">
        Você deseja mesmo <span class="tw-text-2xl tw-font-bold">excluir</span> {{ selectedTitle }}:
        <span v-if="selectedTitle != 'o menu'" class="primary-text">{{ selectedShortcut.name }}</span>
        <span v-else class="primary-text">{{ selectedShortcut.identifier }}</span>
      </div>
      <div class="tw-flex tw-justify-between tw-items-center">
        <div class="tw-text-sm tw-text-gray-400 tw-w-64 tw-pt-4" v-if="selectedTitle == 'o departamento'">
          Se este departamento está vinculado a alguma opção do ChatBot, a opção irá sumir.
        </div>
        <div class="tw-text-sm tw-text-gray-400 tw-w-64 tw-pt-4" v-if="selectedTitle == 'o motivo de finalização'">
          Se este motivo de finalização está vinculado a alguma opção do ChatBot, a opção irá sumir.
        </div>
        <div class="tw-text-sm tw-text-gray-400 tw-w-64 tw-pt-4" v-if="selectedTitle == 'o menu'">
          Se este menu está vinculado a alguma opção do ChatBot, a opção irá sumir.
        </div>
        <div class="tw-w-36 tw-ml-auto tw-pt-4">
        <v-btn dark depressed @click="excludedShortcut()" color="blue"
          >Sim</v-btn
        >
        <v-btn text color="blue" class="tw-ml-2" @click="$emit('close')"
          >Não</v-btn
        >
      </div>
      </div>
      
    </div>
  </v-dialog>
</template>

<script setup>
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState: mapStateConfig, mapActions: mapActionsConfig } =
  createNamespacedHelpers('config');
export default {
  props: { value: Boolean, selectedShortcut: Object, selectedTitle: String },
  data() {
    return {
      newShortcut: {},
      complement: false,
      complements: ['Nenhum', 'Imagem', 'Áudio', 'Documento', 'Localização'],
    };
  },
  methods: {
    ...mapActionsConfig(['deleteShortcut', 'deleteFinishedChatReason', 'deleteTag', 'deleteDepartment', 'deleteMenusChatBot']),
    closeDialog() {
      this.$emit('close', false);
    },
    async excludedShortcut() {
      if (this.selectedTitle == 'o atalho') {
        await this.deleteShortcut({ shortcutId: this.selectedShortcut.id });
      }else if(this.selectedTitle == 'o motivo de finalização'){
        await this.deleteFinishedChatReason({ finishedChatReasonId: this.selectedShortcut.id });
      }else if(this.selectedTitle == 'a etiqueta'){
        await this.deleteTag({ tagId: this.selectedShortcut.id });
      }else if(this.selectedTitle == 'o departamento'){
        await this.deleteDepartment({ departmentId: this.selectedShortcut.id })
      }else if(this.selectedTitle == 'o menu'){
        await this.deleteMenusChatBot(this.selectedShortcut.id);
      }
      this.closeDialog();
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>

.subtitle {
  color: #949494;
}
.mdi-close-circle::before {
  margin-left: 16px;
}
.primary-text {
color: #007aff;
font-weight: 600;
}
.text-content{
  font-family: Arial, Helvetica, sans-serif;
}
</style>
