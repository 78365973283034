<template>
  <v-dialog width="40%" height="70%" v-model="show" @click:outside="closeDialog" scrollable>
    <div class="tw-bg-white tw-py-4 tw-px-6">
      <div class="tw-flex tw-items-center tw-justify-between">
        <div class="tw-text-2xl tw-p-2">Confimar Alteração</div>
        <v-btn @click="closeDialog()" elevation="0" color="#fff" class="tw-mr-2 close-btn tw-rounded-full">
          <close-icon fillColor="#515151" :size=iconSize />
        </v-btn>
      </div>

      <div class="texts">
        <span class="default-text">Se você definir este menu como o de <span class="primary-text">boas-vindas</span>,
          irá <span class="tw-font-bold">retirar</span> está propriedade de <span class="tw-font-bold">outro menu</span>
          que há tenha.</span>
      </div>
      <div class="tw-ml-auto tw-w-56 tw-pt-2 tw-pb-2">
        <v-btn dark depressed @click="confirm()" color="blue">Confirmar</v-btn>
        <v-btn text color="blue" class="tw-ml-2" @click="closeDialog()">Cancelar</v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState: mapStateConfig, mapActions: mapActionsConfig } =
  createNamespacedHelpers('config');
export default {
  props: { value: Boolean, selectedMenu: Object },
  data() {
    return {
      iconSize: 20,
    };
  },
  methods: {
    ...mapActionsConfig(['editMenuChatBot', 'changeWelcomeMenu']),
    closeDialog() {
      this.$emit('close', false);
    },
    confirm() {
      this.changeWelcomeMenu(this.selectedMenu?.id);
      this.$emit('close', true);
    }
  },
  computed: {
    ...mapStateConfig(['menusChatBot']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.subtitle {
  color: #949494;
}

.mdi-close-circle::before {
  margin-left: 16px;
}

.primary-text {
  color: #007aff;
  font-weight: 600;
}

.flex-texts {
  display: flex;
  flex-direction: column;
}

.texts {
  padding: 10px;
}

.default-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}</style>
