import { render, staticRenderFns } from "./CreateNewTag.vue?vue&type=template&id=47fd438b&"
import script from "./CreateNewTag.vue?vue&type=script&setup=true&lang=js&"
export * from "./CreateNewTag.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./CreateNewTag.vue?vue&type=style&index=0&id=47fd438b&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports