<template class="">
  <v-dialog width="70%" persistent v-model="show" @click:outside="closeDialog" scrollable>
    <div class="tw-bg-white tw-p-4 box-height">
      <div class="tw-flex tw-py-1 tw-items-center">
        <div v-if="selectedMenuChatBot" class="tw-text-2xl">
          Editar o {{ selectedMenuChatBot.identifier }}
        </div>
        <div v-else class="tw-text-2xl">Adicionar ChatBot</div>
        <div class="tw-ml-auto">
          <v-btn elevation="0" color="#fff" class="close-btn tw-rounded-full" @click="closeDialog">
            <close-icon fillColor="#515151" />
          </v-btn>
        </div>
      </div>
      <div class="scroll-options custom-scroll tw-flex">
        <div style="min-width: 55%" class="tw-h-full">
          <div>
            <div class="tw-w-full tw-px-3 tw-py-2">
              <div class="tw-flex tw-items-center tw-gap-1">
                <div class="subtitle">
                  Identificador
                  <span class="tw-text-xs">(não será exibido)</span>
                </div>
              </div>
              <v-text-field v-model="newMenuIdentifier" outlined dense hide-details="auto"></v-text-field>
              <div class="tw-text-sm tw-text-red-500" v-if="!createValidation && !newMenuIdentifier">
                Você deve informar o identificador do menu
              </div>
            </div>
          </div>
          <div>
            <div class="tw-w-full">
              <template>
                <v-container fluid>
                  <div class="tw-flex tw-items-center tw-gap-1">
                    <div class="subtitle">Mensagem de Menu</div>
                    <v-tooltip bottom max-width="200">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <info-icon iconColor="#515151" iconSize="14px" />
                        </div>
                      </template>
                      <span class="font-tooltip">Mensagem enviada ao cliente antes das opções de
                        resposta</span>
                    </v-tooltip>
                  </div>
                  <v-textarea class="custom-scroll" no-resize clearable outlined clear-icon="mdi-close-circle"
                    v-model="newMenuTitle"></v-textarea>
                  <div class="tw-py-5 tw--mt-12 tw-text-sm tw-text-red-500" v-if="!createValidation && !newMenuTitle">
                    Você deve informar a mensagem de menu do menu
                  </div>
                  <div class="tw-p-2 tw--mt-4">
                    <div class="tw-flex tw-items-center tw-gap-1">
                      <div class="subtitle">Status</div>
                      <v-tooltip bottom max-width="200">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <info-icon iconColor="#515151" iconSize="14px" />
                          </div>
                        </template>
                        <span class="font-tooltip">Ativar/Desativar o menu</span>
                      </v-tooltip>
                    </div>
                    <div class="tw-px-3 tw--mt-2">
                      <v-switch v-model="newMenuStatus" class="tw-w-8" hide-details="auto"></v-switch>
                    </div>
                  </div>
                  <div class="tw-p-2">
                    <div class="tw-flex tw-items-center tw-gap-1">
                      <div class="subtitle">Menu de boas-vindas</div>
                      <v-tooltip bottom max-width="200">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <info-icon iconColor="#515151" iconSize="14px" />
                          </div>
                        </template>
                        <span class="font-tooltip">Este será o menu que aparecerá ao primeiro contato do cliente</span>
                      </v-tooltip>
                    </div>
                    <div class="tw-px-3 tw--mt-2">
                      <v-switch v-if="!newMenuIsWelcome" @change="onChangeWelcomeMenu(selectedMenuChatBot)"
                        v-model="newMenuIsWelcome" class="tw-w-8" hide-details="auto"></v-switch>
                      <v-switch v-else v-model="newMenuIsWelcome" class="tw-w-8" hide-details="auto"></v-switch>
                    </div>
                  </div>
                </v-container>
              </template>
            </div>
            <div class="tw-w-full">
              <fieldset class="tw-border tw-rounded-md tw-p-5">
                <legend class="tw-font-semibold">Opções do Menu</legend>
                <div class="tw-border-b-2 tw-rounded-sm tw-my-2" v-for="option in newOptions">
                  <div class="tw-flex tw-items-center tw-justify-between">
                    <div class="tw-w-16">
                      <div>Opção</div>
                      <div class="option-style tw-ml-2">{{ option.number }}</div>
                    </div>
                    <div class="tw-w-30">
                      <div>Título da opção</div>
                      <v-text-field v-model="option.title" outlined dense hide-details>
                      </v-text-field>
                    </div>
                    <div class="tw-w-30">
                      <div class="tw-flex tw-items-center tw-h-6 tw-content-between"
                        style="justify-content: space-between;">
                        <div>Ação</div>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="option.number != 1" elevation="0" v-bind="attrs" v-on="on" color="#fff"
                              class="remove-opt tw-rounded-full tw-mb-2" @click="removeOption(option)">
                              <close-icon fillColor="#515151" :size=iconSize />
                            </v-btn>
                          </template>
                          <span>Remover Opção</span>
                        </v-tooltip>

                      </div>
                      <v-select :items="actions" outlined dense hide-details class="menu-input tw-text-sm"
                        v-model="option.action">
                      </v-select>
                    </div>
                  </div>

                  <div class="tw-p-4 tw--ml-10">
                    <div v-if="option.action == 'Transferir para departamento'" class="tw-w-30 tw-items-center tw-ml-10">
                      <div>Departamento para transferir</div>
                      <v-select v-model="option.departmentTransferId" :items="departments" item-value="id"
                        item-text="name" :item-disabled="disabledItem" outlined dense hide-details class="tw-text-sm tw-w-full">
                      </v-select>
                    </div>
                    <div v-else-if="option.action == 'Saltar para outro Menu'" class="tw-w-30 tw-items-center tw-ml-10">
                      <div>Menu</div>
                      <v-select v-model="option.menuTransferId" :items="menusToChoose(menusChatBot)" item-value="id"
                        item-text="identifier" :item-disabled="disabledItem" outlined dense hide-details class="tw-text-sm tw-w-full">
                      </v-select>
                    </div>
                    <div v-else-if="option.action == 'Finalizar Atendimento'" class="tw-w-30 tw-items-center tw-ml-10">
                      <div>
                        <div>Motivo de Finalizar o Atendimento</div>
                        <v-select v-model="option.finishedTransferId" :items="finishedChatReasons" item-value="id"
                          item-text="name" :item-disabled="disabledItem" outlined dense hide-details class="tw-text-sm tw-w-full">
                        </v-select>
                      </div>
                      <div class="tw-mt-4">
                        <div class="tw-flex tw-items-center tw-gap-1">
                          <div>
                            Mensagem de Conclusão
                            <span class="tw-text-xs tw-font-light">(opcional)</span>
                          </div>
                          <v-tooltip bottom max-width="200">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <info-icon iconColor="#515151" iconSize="14px" />
                              </div>
                            </template>
                            <span class="font-tooltip">Mensagem enviada ao cliente ao concluir o
                              atendimento</span>
                          </v-tooltip>
                        </div>
                        <div v-if="option.finishedTransferId" class="tw--mb-6">
                          <div v-for="finishedChat in finishedChatReasons" class="">
                            <v-textarea v-if="finishedChat.id == option.finishedTransferId" v-model="finishedChat.message"
                              disabled="false" no-resize clearable outlined clear-icon="mdi-close-circle"></v-textarea>
                          </div>
                        </div>
                        <div v-else class="tw--mb-6">
                          <v-textarea disabled="false" no-resize clearable outlined
                            clear-icon="mdi-close-circle"></v-textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tw-flex tw-items-center tw-justify-between">
                  <div class="tw-w-32">
                    <v-btn depressed dark color="#177dff" @click="addOption">
                      <add-icon iconColor="#ffffff" iconSize="24px" class="tw--ml-2" />
                      <div class="tw-text-base">Adicionar</div>
                    </v-btn>
                  </div>
                  <div v-if="!optionsValidation" class="tw-mr-2 tw-w-64 tw-text-center tw-text-sm tw-text-red-500">
                    Preencha todas as informações necessárias das opções para confirmar
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div class="tw-w-full tw-p-4">
          <div class="phone tw-p-6">
            <div class="message-section tw-p-2 tw-bg-green-200 tw-rounded-md tw-ml-auto tw-h-full">
              <div v-if="newMenuTitle" class="tw-pb-2">
                {{ newMenuTitle }}
              </div>
              <div v-for="option in newOptions" class="tw-items-center">
                {{ option.number }} - {{ option.title }}
              </div>
            </div>
          </div>
          <div class="btn-position tw-w-56 tw-mt-8 tw-pb-4">
            <v-btn dark depressed @click="createMenu()" color="blue">Confirmar</v-btn>
            <v-btn text color="blue" class="tw-ml-2" @click="$emit('close')">Cancelar</v-btn>
            <!-- <div v-if="!createValidation || !optionsValidation" class="tw-pt-4 tw-text-center tw-text-sm tw-text-red-500">
              Ops! Há alguma informação faltando para a validação do formulário.
            </div> -->
          </div>
        </div>
      </div>
      <confirm-change-welcome-menu v-model="modelDialogConfirmChangeWelcomeMenu"
        v-if="modelDialogConfirmChangeWelcomeMenu" :selected-menu="selectedMenu" @close="(res) => closeModal(res)" />
    </div>
  </v-dialog>
</template>

<script setup>
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions: mapActionsConfig, mapState: mapStateConfig } =
  createNamespacedHelpers('config');

import ConfirmChangeWelcomeMenu from './../modals/ConfirmChangeWelcomeMenu.vue';

export default {
  components: {
    ConfirmChangeWelcomeMenu
  },
  props: { value: Boolean, selectedMenuChatBot: Object, optionsMenuChatBot: Array },
  data() {
    return {
      optionCouter: 1,
      newMenu: { identifier: '', title: '', status: '', isWelcomeMenu: '' },
      newMenuIdentifier: "",
      iconSize: 15,
      noDisabled: false,
      removeOptiosIds: [],
      newMenuTitle: "",
      newMenuStatus: true,
      modelDialogConfirmChangeWelcomeMenu: false,
      selectedMenu: null,
      newMenuIsWelcome: false,
      startLength: 0,
      createValidation: true,
      optionsValidation: true,
      newOptions: [{ title: '', number: 1, action: '' }],
      actions: [
        'Transferir para departamento',
        'Saltar para outro Menu',
        'Finalizar Atendimento',
      ],

    };
  },
  methods: {
    ...mapActionsConfig(['createMenuChatBot', 'editMenuChatBot', 'createOptionChatBot', 'editOptionsChatBot', 'deleteOptionsChatBot']),
    closeDialog() {
      this.$emit('close', false);
    },
    closeModal(res) {
      this.newMenuIsWelcome = res;
      this.modelDialogConfirmChangeWelcomeMenu = false;
      this.selectedMenu = null;
    },
    onChangeWelcomeMenu(menu) {
      this.selectedMenu = menu;
      this.modelDialogConfirmChangeWelcomeMenu = true;
    },
    addOption() {
      this.optionCouter++;
      this.newOptions.push({
        number: this.optionCouter,
        title: '',
        action: '',
      });
    },
    async createMenu() {
      if (this.newMenuIdentifier && this.newMenuTitle) {
        const vali = this.newOptions.filter((item) => {
          if (item.action == '' || item.title == '') {
            return false;
          } else {
            if (item.action == 'Transferir para departamento' && !item.departmentTransferId) {
              return false
            }
            else if (item.action == 'Saltar para outro Menu' && !item.menuTransferId) {
              return false
            }
            else if (item.action == 'Finalizar Atendimento' && !item.finishedTransferId) {
              return false
            }
            else {
              return item;
            }
          }
        });
        if (this.newOptions.length != vali.length) {
          this.optionsValidation = false;
        } else {
          this.optionsValidation = true;
        }
        if (this.optionsValidation) {
          if (this.selectedMenuChatBot?.id) {
            this.selectedMenuChatBot.identifier = this.newMenuIdentifier;
            this.selectedMenuChatBot.title = this.newMenuTitle;
            this.selectedMenuChatBot.status = this.newMenuStatus;
            this.selectedMenuChatBot.isWelcomeMenu = this.newMenuIsWelcome;
            this.editMenuChatBot(this.selectedMenuChatBot);
            if (this.removeOptiosIds.length) {
              this.deleteOptionsChatBot(this.removeOptiosIds);
            }
            this.newOptions.map((item) => {
              item ? item.menuId = this.selectedMenuChatBot.id : null;
            })
            this.editOptionsChatBot(this.newOptions);
            this.closeDialog();
          } else {
            this.newMenu.identifier = this.newMenuIdentifier;
            this.newMenu.title = this.newMenuTitle;
            this.newMenu.status = this.newMenuStatus;
            this.newMenu.isWelcomeMenu = this.newMenuIsWelcome;
            const id = await this.createMenuChatBot(this.newMenu);
            this.newOptions.map((item) => {
              item ? item.menuId = id : null
            });
            this.createOptionChatBot(this.newOptions);
            this.closeDialog();
          }
        }
      }
      else {
        this.createValidation = false;
      }
    },
    removeOption(op) {
      if (this.selectedMenuChatBot?.id) {
        this.removeOptiosIds.push(op.id);
      }
      const number = op.number;
      const i = this.newOptions.findIndex((item) => item.number === number);
      this.newOptions.splice(i, 1);
      this.newOptions.map((item) => {
        if (item.number > number) {
          item.number--;
        }
      })
      this.optionCouter--;
    },
    menusToChoose(menus) {
      if (this.selectedMenuChatBot) {
        const newMenus = menus.filter((item) => item.id != this.selectedMenuChatBot.id);
        return newMenus;
      } else {
        return menus;
      }

    },
    disabledItem(el) {
      if (el.status == false)
        return true
      return false
    },
  },
  computed: {
    ...mapStateConfig(['departments', 'finishedChatReasons', 'optionsChatBot', 'menusChatBot']),
    
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    if (this.selectedMenuChatBot?.id) {
      this.newMenuIdentifier = this.selectedMenuChatBot.identifier;
      this.newMenuTitle = this.selectedMenuChatBot.title;
      this.newMenuStatus = this.selectedMenuChatBot.status;
      this.newMenuIsWelcome = this.selectedMenuChatBot.isWelcomeMenu;
      if (this.optionsMenuChatBot.length) {
        this.newOptions = this.optionsMenuChatBot;
        this.optionCouter = this.optionsMenuChatBot.length;
      }
    }
  },
};
</script>

<style>
.menu-input .v-input__slot {
  width: 150px !important;
}

.option-style {
  border: 1px solid #9e9e9e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12;
  border-radius: 4px;
  width: 40px;
  height: 40px;
}

.phone {
  background-color: #d5e8d5;
  max-width: 280px;
  min-width: 280px;
  min-height: 550px;
  max-height: 80%;
  margin-left: 15%;
  margin-top: 14px;
  box-shadow: rgb(31, 31, 31) 0px 0px 0px 12px, rgb(25, 25, 25) 0px 0px 0px 12px,
    rgb(17, 17, 17) 0px 0px 0px 12px;
  border-radius: 20px;
}

.message-section {
  max-width: calc(100% - 20px);
  word-wrap: break-word;
}

.btn-position {
  margin-left: 25%;
}

.box-height {
  height: 600px;
  overflow: hidden;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 103% !important;
}

.remove-opt {
  min-width: 15px !important;
  height: 23px !important;
  padding: 5px !important;
}
</style>
<style scoped>
.scroll-options {
  height: 520px;
  overflow-y: scroll !important;
}
</style>
