<template>
  <v-dialog width="570px" height="70%" persistent v-model="show" @click:outside="closeDialog" scrollable>
    <div class="tw-bg-white tw-p-4">
      <div class="tw-flex tw-py-2 tw-items-center tw-justify-between tw-px-2">
        <div class="tw-text-2xl">Leia o QRCODE</div>
        <div>
          <v-btn elevation="0" color="#fff" class="close-btn tw-rounded-full" @click="closeDialog">
            <close-icon fillColor="#515151" />
          </v-btn>
        </div>
      </div>
      <div>
        <div v-if="messageQrcode == 'Dispositivo pronto!'" class="tw-flex tw-justify-center tw-items-center tw-flex-col">
          <check-circle-icon :iconSize="150" iconColor="#19bb85" />
          <div class="tw-text-green-500">
            {{ messageQrcode }}
          </div>
        </div>
        <div v-else-if="messageQrcode == 'Dispositivo desconectado!'"
          class="tw-flex tw-justify-center tw-items-center tw-flex-col">
          <error-icon iconColor="#ef4444" :iconSize="130" />
          <div class="tw-text-red-500">
            {{ messageQrcode }}
          </div>
        </div>
        <div v-else-if="messageQrcode == 'Aguarde enquanto pegamos as informações de seu WhatsApp...'" class="tw-mt-2
          tw-p-6 tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-6">
          <div class="qr-loader">
            <v-progress-circular :color="colorGreen" :size="75" indeterminate></v-progress-circular>
          </div>
          <div class="tw-text-gray-400 tw-font-light tw-text-sm">
            {{ messageQrcode }}
          </div>
          <div v-if="waitMinutes" class="tw-text-gray-400 tw-font-light tw-text-sm tw--mt-4">
            Isso pode demorar alguns minutos.
          </div>
        </div>
        <div v-else-if="qrcode">
          <div class="tw-w-full tw-flex tw-content-end tw-items-center tw-flex-col">
            <img :src="qrcode" />
            <div>
              {{ messageQrcode }}
            </div>
          </div>
        </div>
        <div v-else class="tw-mt-2 tw-p-6 tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-6">
          <div class="qr-loader">
            <v-progress-circular color="primary" :size="50" indeterminate></v-progress-circular>
          </div>
          <div class="tw-text-gray-400 tw-font-light tw-text-sm">
            Gerando QRCODE...
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState: mapStateConfig, mapActions: mapActionsConfig } =
  createNamespacedHelpers('config');
export default {
  props: { value: Boolean },
  data() {
    return {
      colorGreen: '#25d366',
      waitMinutes: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close', false);
    },
  },
  watch: {
    messageQrcode(newVal, oldVal) {
      if (newVal == 'Aguarde enquanto pegamos as informações de seu WhatsApp...') {
        setTimeout(() => {
          console.log("Delayed for 1 second.");
          this.waitMinutes = true
        }, "8000");
      }
      if(newVal == 'Dispositivo Pronto!') {
        this.waitMinutes = false
      }
    }
  },
  computed: {
    ...mapStateConfig(['qrcode', 'messageQrcode']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.close-btn {
  min-width: 36px !important;
  width: 36px;
  border-radius: 36px;
  height: 36px;
}

.v-color-picker__alpha:not(.v-input--is-disabled) .v-slider {
  display: none;
}

.v-color-picker__dot {
  display: none;
}

.v-slider__thumb::after {
  width: 20px !important;
  max-height: 20px !important;
}

canvas {
  border-radius: 10px !important;
  border: 1px solid #cfcfcf;
}

.subtitle {
  color: #949494;
}

.v-color-picker__controls {
  padding: 10px 16px !important;
  height: 20px;
}

.qr-loader .v-progress-circular {
  margin-left: 0% !important;
  margin-top: 0% !important;
}
</style>
