<template>
  <v-dialog
    width="40%"
    height="70%"
    persistent
    v-model="show"
    @click:outside="closeDialog"
    scrollable
  >
    <div class="tw-bg-white tw-p-4">
      <div class="tw-flex tw-py-2 tw-items-center">
        <div v-if="selectedTag.id" class="tw-text-2xl">Editar Etiqueta</div>
        <div v-else class="tw-text-2xl">Adicionar Etiqueta</div>
        <div class="tw-ml-auto tw-mr-2">
          <v-btn
            elevation="0"
            color="#fff"
            class="close-btn tw-rounded-full"
            @click="closeDialog"
          >
            <close-icon fillColor="#515151" />
          </v-btn>
        </div>
      </div>
      <div class="tw-px-4 tw-pt-4">
        <div class="tw-flex tw-gap-10">
          <div class="tw-w-2/5">
            <div class="subtitle">Nome</div>
            <div class="tw-w-full">
              <v-text-field
                v-if="selectedTag.id"
                v-model="newName"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
              <v-text-field
                v-else
                v-model="newName"
                outlined
                dense
                :rules="rules"
                hide-details="auto"
              ></v-text-field>
              <span v-if="(!editValidation && !newName) || (!createValidation && !newName)" class="tw-text-xs tw-text-red-500">
                Você deve informar o nome da etiqueta
              </span>
            </div>
            <div class="tw-ml-8 tw-mt-16">
              <label-icon
                v-if="selectedTag.id"
                :iconColor="newTag.color"
                iconSize="100px"
              />
              <label-icon v-else :iconColor="newColor" iconSize="100px" />
            </div>
            <div></div>
          </div>
          <div>
            <v-color-picker
              v-if="selectedTag.id"
              v-model="newTag.color"
              mode="hex"
              canvas-height="100"
              width="250"
              :swatches="swatches"
              show-swatches
            ></v-color-picker>
            <v-color-picker
              v-else
              v-model="newColor"
              mode="hex"
              canvas-height="100"
              width="250"
              :swatches="swatches"
              show-swatches
            ></v-color-picker>
          </div>
        </div>
      </div>
      <div class="tw-w-56 tw-ml-auto tw-mt-4">
        <v-btn dark depressed @click="createNewTag()" color="blue"
          >Confirmar</v-btn
        >
        <v-btn text color="blue" class="tw-ml-2" @click="$emit('close')"
          >Cancelar</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState: mapStateConfig, mapActions: mapActionsConfig } =
  createNamespacedHelpers('config');
export default {
  props: { value: Boolean, selectedTag: Object },
  data() {
    return {
      newTag: this.selectedTag,
      editValidation: true,
      createValidation: true,
      newName: this.selectedTag.name,
      newColor: '',
      swatches: [
        ['#a600ff', '#FF00FF', '#ffa7f9'],
        ['#FF0000', '#ff8c00', '#FFFF00'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#0000FF', '#009dff', '#00f8ff'],
      ],
    };
  },
  methods: {
    ...mapActionsConfig(['createTag', 'editTag']),
    closeDialog() {
      this.$emit('close', false);
    },
    async createNewTag() {
      if (this.selectedTag.id) {
        if (this.newName) {
          this.selectedTag.name = this.newName;
          await this.editTag({ tag: this.selectedTag });
          this.editValidation = true;
          this.closeDialog()
        } else {
          this.editValidation = false;
        }
      } else {
        if (this.newName) {
          this.newTag.name = this.newName;
          this.newTag.color = this.newColor;
          await this.createTag({ tag: this.newTag });
          this.createValidation = true;
          this.closeDialog()
        }else{
          this.createValidation = false;
        }
      }
    },
  },
  computed: {
    ...mapStateConfig(['tags']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.close-btn {
  min-width: 36px !important;
  width: 36px;
  border-radius: 36px;
  height: 36px;
}
.v-color-picker__alpha:not(.v-input--is-disabled) .v-slider {
  display: none;
}
.v-color-picker__dot {
  display: none;
}
.v-slider__thumb::after {
  width: 20px !important;
  max-height: 20px !important;
}
canvas {
  border-radius: 10px !important;
  border: 1px solid #cfcfcf;
}
.subtitle {
  color: #949494;
}
.v-color-picker__controls {
  padding: 10px 16px !important;
  height: 20px;
}
</style>
