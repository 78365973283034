<template>
  <v-dialog
    width="40%"
    height="70%"
    persistent
    v-model="show"
    @click:outside="closeDialog"
    scrollable
  >
    <div class="tw-bg-white tw-p-4">
      <div class="tw-flex tw-py-2 tw-items-center">
        <div class="tw-text-2xl">Adicionar Motivo de Finalização</div>
        <div class="tw-ml-auto tw-mr-2">
          <v-btn
            elevation="0"
            color="#fff"
            class="close-btn tw-rounded-full"
            @click="closeDialog"
          >
            <close-icon fillColor="#515151"  />
          </v-btn>
        </div>
      </div>
      <div>
        <div class="tw-w-4/6 tw-px-3 tw-py-2">
          <div class="tw-flex tw-items-center tw-gap-1">
            <div class="subtitle">Nome</div>
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <info-icon iconColor="#515151" iconSize="14px" />
                </div>
              </template>
              <span class="font-tooltip">Título do motivo de finalização</span>
            </v-tooltip>
          </div>
          <v-text-field
            v-if="selectedMotive.id"
            v-model="newName"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
          <v-text-field
            v-else
            outlined
            v-model="newName"
            dense
            hide-details="auto"
          ></v-text-field>
          <span
            v-if="
              (!editValidation && !newName) || (!createValidation && !newName)
            "
            class="tw-text-xs tw-text-red-500"
          >
            Você deve informar o nome do motivo
          </span>
        </div>
      </div>
      <div>
        <div class="">
          <template>
            <v-container fluid>
              <div class="tw-flex tw-items-center tw-gap-1">
                <div class="subtitle">Mensagem de Finalização <span class="tw-text-xs">(opcional)</span></div>
                <v-tooltip bottom max-width="200">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <info-icon iconColor="#515151" iconSize="14px" />
                    </div>
                  </template>
                  <span class="font-tooltip"
                    >Mensagem a ser enviada quando a sessão for finalizada por
                    este departamento</span
                  >
                </v-tooltip>
              </div>
              <v-textarea
                v-if="selectedMotive.id"
                v-model="newMessage"
                no-resize
                clearable
                outlined
                clear-icon="mdi-close-circle"
              ></v-textarea>
              <v-textarea
                v-else
                no-resize
                clearable
                outlined
                v-model="newMessage"
                clear-icon="mdi-close-circle"
              ></v-textarea>
            </v-container>
          </template>
        </div>
      </div>
      <div class="tw-w-56 tw-ml-auto tw--mt-4">
        <v-btn dark depressed @click="createNewFinishedChatReason" color="blue"
          >Confirmar</v-btn
        >
        <v-btn text color="blue" class="tw-ml-2" @click="$emit('close')"
          >Cancelar</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState: mapStateConfig, mapActions: mapActionsConfig } =
  createNamespacedHelpers('config');
export default {
  props: { value: Boolean, selectedMotive: Object },
  data() {
    return {
      newReason: this.selectedMotive,
      newName: this.selectedMotive.name,
      newMessage: this.selectedMotive.message,
      editValidation: true,
      createValidation: true,
    };
  },
  methods: {
    ...mapActionsConfig(['createFinishedChatReason', 'editFinishedChatReason']),
    closeDialog() {
      this.$emit('close', false);
    },
    async createNewFinishedChatReason() {
      if (this.selectedMotive.id) {
        if (this.newName) {
          this.selectedMotive.name = this.newName;
          this.selectedMotive.message = this.newMessage;
          await this.editFinishedChatReason({

            finishedChatReason: this.selectedMotive,
          });
          this.closeDialog();
        } else {
          this.editValidation = false;
        }
      } else {
        if (this.newName) {
          this.newReason.name = this.newName;
          this.newReason.message = this.newMessage;
          await this.createFinishedChatReason({
            finishedChatReason: this.newReason,
          });
          this.closeDialog();
        } else {
          this.createValidation = false;
        }
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.subtitle {
  color: #949494;
}
.mdi-close-circle::before {
  margin-left: 16px;
}
</style>
