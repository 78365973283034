<template>
  <v-dialog width="40%" height="70%" v-model="show" @click:outside="closeDialog" scrollable>
    <div class="tw-bg-white tw-p-4">
      <div class="tw-flex tw-py-2 tw-items-center">
        <div v-if="selectedDepartment.id" class="tw-text-2xl">Editar Departamento</div>
        <div v-else class="tw-text-2xl">Adicionar Departamento</div>
        <div class="tw-ml-auto tw-mr-2">
          <v-btn elevation="0" color="#fff" class="close-btn tw-rounded-full" @click="closeDialog()">
            <close-icon fillColor="#515151" />
          </v-btn>
        </div>
      </div>
      <div>
        <div class="tw-flex-col tw-my-2">
          <div class="subtitle">Nome</div>
          <v-text-field v-if="selectedDepartment.id" v-model="newName" outlined dense hide-details="auto"></v-text-field>
          <v-text-field v-else v-model="newName" outlined dense hide-details="auto"></v-text-field>
          <span v-if="(!editValidation && !newName) || (!createValidation && !newName)
            " class="tw-text-xs tw-text-red-500">
            Informe o nome do departamento
          </span>
        </div>
      </div>
      <div>
        <div>
          <div class="tw-flex tw-items-center tw-gap-1">
            <div class="subtitle">Mensagem de Boas Vindas <span class="tw-text-sm">(opcional)</span></div>
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <info-icon iconColor="#515151" iconSize="14px" />
                </div>
              </template>
              <span class="font-tooltip">Mensagem automática a ser enviada quando um cliente entrar
                no departamento</span>
            </v-tooltip>
          </div>
          <template v-if="selectedDepartment.id">
            <v-textarea hide-details class="custom-scroll" v-model="newMessage" no-resize clearable outlined
              placeholder="Seja bem vindo..." clear-icon="mdi-close-circle"></v-textarea>
          </template>
          <template v-else>

            <v-textarea hide-details v-model="newMessage" class="custom-scroll" no-resize rows="6" clearable outlined
              placeholder="Seja bem vindo..." clear-icon="mdi-close-circle"></v-textarea>

          </template>
          <div>
            <div class="tw-flex tw-items-center tw-gap-1 mt-2">
              <div class="subtitle">Usuários deste departamento</div>
              <v-tooltip bottom max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <info-icon iconColor="#515151" iconSize="14px" />
                  </div>
                </template>
                <span class="font-tooltip">Está é a lista de usuários que terão ascesso à conversas atreladas a este
                  departamento</span>
              </v-tooltip>
            </div>
            <div >
              <v-select outlined class="custom-scroll" v-model="usersSelecteds" item-text="name" item-value="id"
                :items="users" chips multiple></v-select>
            </div>
            <div v-if="(!editValidation || !createValidation) && usersSelecteds.length <= 0"
               class="tw-text-xs tw-text-red-500 tw--mt-6">
              Um departamento deve ter pelo menos um usuário com acesso
            </div>
          </div>
        </div>
      </div>
      <div class="tw-w-56 tw-mt-2 tw-ml-auto">
        <v-btn dark depressed @click="createNewDepartment" color="blue">Confirmar</v-btn>
        <v-btn text color="blue" class="tw-ml-2" @click="$emit('close')">Cancelar</v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script setup>
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState: mapStateConfig, mapActions: mapActionsConfig } =
  createNamespacedHelpers('config');
export default {
  props: { value: Boolean, selectedDepartment: Object },
  data() {
    return {
      usersSelecteds: this.selectedDepartment.users || [],
      newDepartment: this.selectedDepartment,
      newName: this.selectedDepartment.name || null,
      newMessage: this.selectedDepartment.message,
      createValidation: true,
      editValidation: true,
    };
  },
  methods: {
    ...mapActionsConfig(['createDepartment', 'editDepartment']),
    closeDialog() {
      this.$emit('close', false);
    },
    async createNewDepartment() {
      if (this.selectedDepartment.id) {
        if (this.newName && this.usersSelecteds.length > 0) {
          this.selectedDepartment.name = this.newName;
          this.selectedDepartment.message = this.newMessage;
          this.selectedDepartment.users = [];
          this.usersSelecteds.forEach((item) => {
            if (typeof item === 'object' && item !== null) {
              this.selectedDepartment.users.push({ userId: item.id });
            } else {
              this.selectedDepartment.users.push({ userId: item });
            }
          })
          await this.editDepartment({ department: this.selectedDepartment })
          this.closeDialog();
        } else {
          this.editValidation = false;
        }
      } else {
        if (this.newName != null && this.usersSelecteds && this.usersSelecteds.length > 0) {
          this.newDepartment.name = this.newName;
          this.newDepartment.message = this.newMessage;
          this.newDepartment.users = [];
          this.usersSelecteds.forEach((item) => {
            this.selectedDepartment.users.push({ userId: item });
          })
          await this.createDepartment({ department: this.newDepartment });
          this.closeDialog();
        } else {
          this.createValidation = false;
        }
      }
    },
  },
  computed: {
    ...mapStateConfig(['users']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.subtitle {
  color: #949494;
}

.v-menu__content::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.mdi-close-circle::before {
  margin-left: 16px;
}
</style>
